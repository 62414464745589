import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import EComImg from '../../../images/ecom.jpg'

const paginaServizio = {
  titolo: 'E Com',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: EComImg,
      paragrafi: [
        {
          id: 1,
          testo:
            "Una parte fondamentale del commercio online è rappresentato dalla gestione delle spedizioni delle merci acquistate. Tutte le transazioni commerciali di beni o servizi che avvengono nella rete tra imprese e consumatori non potrebbero essere concluse senza l'uso di un servizio di spedizione dedicato.",
        },
        {
          id: 2,
          testo:
            "L'e-commerce prevede molto spesso una consegna ai privati che non di rado sono difficili da raggiungere durante la giornata lavorativa.",
        },
        {
          id: 3,
          testo:
            "Per tutti questi motivi, GLS ha studiato eCom-Service, un servizio dedicato che rende più diretto il controllo dell'intero processo di spedizione e ti solleva dalla gestione delle problematiche tipiche della consegna ai privati.",
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo:
            'Possibilità di invio automatico di una notifica alla partenza della spedizione e di un preavviso il giorno stesso della consegna se in combinazione con Info-Service',
        },
        {
          id: 2,
          testo: 'Avviso via e-mail al destinatario per eventuali non consegne',
        },
        {
          id: 3,
          testo: 'Gestione della riconsegna di competenza del destinatario',
        },
        {
          id: 4,
          testo: 'Uso di un link dedicato, raggiungibile 24 ore sul 24',
        },
        {
          id: 5,
          testo: 'Ottimizza i tempi',
        },
        {
          id: 6,
          testo:
            'Migliora la reperibilità dei destinatari e riduce eventuali rischi di giacenza',
        },
        {
          id: 7,
          testo: 'Controllo totale della spedizione',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Safe Plus',
          linkUrl: '/servizi/safe-plus',
        },
        {
          id: 3,
          nome: 'Servizio Info',
          linkUrl: '/servizi/accessori/info',
        },
      ],
      servizi: null,
    },
  ],
}

const ECom = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default ECom
